import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { useSiteMetadata } from 'hooks/use-site-metadata'

const Metadata = ({
  title,
  description,
  banner,
  noIndex,
  keywords,
  ogTitle,
  ogDescription,
  twTitle,
  twDescription,
  jsonTitle,
  jsonDescription,
  isHomepage,
}) => {
  const {
    siteMetadata: {
      banner: siteBanner,
      url: siteUrl,
      title: siteTitle,
      titleTemplate,
      description: siteDescription,
      keywords: siteKeywords,
      og_title,
      og_description,
      tw_title,
      tw_description,
      json_title,
      json_description,
    },
  } = useSiteMetadata()

  const metaTitle = title || siteTitle
  const metaDescription = description || siteDescription
  const metaBanner = banner ? `${siteUrl}${banner}` : siteBanner
  const metaKeywords = keywords || siteKeywords
  const openGraphTitle = ogTitle || og_title
  const openGraphDescription = ogDescription || og_description
  const twitterTitle = twTitle || tw_title
  const twitterDescription = twDescription || tw_description
  const structuredTitle = jsonTitle || json_title
  const structuredDescription = jsonDescription || json_description

  const metaOgTitle = isHomepage ? openGraphTitle : metaTitle
  const metaOgDescription = isHomepage ? openGraphDescription : metaDescription
  const metaTwTitle = isHomepage ? twitterTitle : metaTitle
  const metaTwDescription = isHomepage ? twitterDescription : metaDescription
  const metaJsonTitle = isHomepage ? structuredTitle : metaTitle
  const metaJsonDescription = isHomepage
    ? structuredDescription
    : metaDescription

  return (
    <Helmet titleTemplate={titleTemplate}>
      {/* General */}
      <title>{metaTitle}</title>

      {isHomepage && (
        <script type="application/ld+json">{`
        [{
          "@context": "https://schema.org/",
          "@type": "CollectionPage",
          "name": "${metaJsonTitle}",
          "description": "${metaJsonDescription}"
        }]
      `}</script>
      )}

      <meta name="description" content={metaDescription} />

      {isHomepage && <meta name="keywords" content={metaKeywords} />}

      <meta name="image" content={metaBanner} />

      {/* Open Graph */}
      <meta property="og:title" content={metaOgTitle} />
      <meta property="og:description" content={metaOgDescription} />
      <meta property="og:image" content={metaBanner} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="565" />
      <meta property="og:type" content="website" />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaTwTitle} />
      <meta name="twitter:description" content={metaTwDescription} />
      <meta name="twitter:image" content={metaBanner} />

      {/* Noindexing */}
      {noIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  )
}

Metadata.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  banner: PropTypes.string,
  noIndex: PropTypes.bool,
}

Metadata.defaultProps = {
  title: '',
  description: '',
  banner: '',
  noIndex: false,
}

export default Metadata
