import { useStaticQuery, graphql } from 'gatsby'

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        assetPrefix
        siteMetadata {
          baseUrl
          title
          titleTemplate
          eventTitle
          description
          url
          banner
          keywords
          og_title
          og_description
          tw_title
          tw_description
          json_title
          json_description
        }
      }
    }
  `)
  return site
}
