module.exports = {
  assetPrefix: `/games`,
  trailingSlash: `ignore`,
  siteMetadata: {
    title: `Play Free-to-Play and Game Vault Anime-Inspired Games`,
    titleTemplate: `Crunchyroll Games: %s`,
    eventTitle: `CR Games Home`,
    description: `Explore Crunchyroll Games and discover a collection of anime-inspired titles. Play The Eminence in Shadow and Street Fighter: Duel (free-to-play) and enjoy Game Vault games like Sweet Sins 2, Crypt of the Necrodancer, Steins;Gate, Kardboard Kings, and Tengami, free with a Crunchyroll Megafan or higher subscription.`,
    url: `https://crunchyroll.com/games`,
    baseUrl: `https://crunchyroll.com`,
    banner: `https://crunchyroll.com/games/default-banner.jpg`,
    keywords: `Crunchyroll Games, anime-inspired games, free-to-play games, Game Vault, The Eminence in Shadow, Street Fighter Duel, Sweet Sins 2, Crypt of the Necrodancer, Steins;Gate, Kardboard Kings, Tengami, Megafan subscription, free games`,
    og_title: `Crunchyroll Games: Play Free-to-Play and Game Vault Anime-Inspired Games`,
    og_description: `Explore Crunchyroll Games to play free-to-play hits like The Eminence in Shadow and Street Fighter: Duel and Game Vault titles like Sweet Sins 2, Crypt of the Necrodancer, Steins;Gate, Kardboard Kings, and Tengami. Game Vault games are free with a Crunchyroll Megafan or higher subscription.`,
    tw_title: `Crunchyroll Games: Play Free-to-Play and Game Vault Anime-Inspired Games`,
    tw_description: `Play The Eminence in Shadow and Street Fighter: Duel on Crunchyroll Games. Game Vault titles like Sweet Sins 2, Crypt of the Necrodancer, and Steins;Gate are available for free with a Crunchyroll Megafan or higher subscription.`,
    json_title: `Crunchyroll Games – Free-to-Play and Game Vault Titles`,
    json_description: `Explore Crunchyroll’s free-to-play games and Game Vault collection. Play The Eminence in Shadow and Street Fighter: Duel (free-to-play) or discover Game Vault titles like Sweet Sins 2, Crypt of the Necrodancer, Steins;Gate, Kardboard Kings, and Tengami for free with a Crunchyroll Megafan or higher subscription.`,
  },
  plugins: [
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `data`,
        path: `${__dirname}/src/data`,
      },
    },
    `gatsby-transformer-json`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-plugin-emotion`,
    `gatsby-plugin-react-helmet`,
    `gatsby-transformer-sharp`,
    {
      resolve: `gatsby-plugin-sharp`,
      options: {
        defaults: {
          formats: [`auto`, `webp`],
          quality: 80,
        },
      },
    },
    `gatsby-plugin-image`,
  ],
}
